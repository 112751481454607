@import '../../general.css';

.navbar {
  height: 70px;
  /* width: 100%; */
  background-color: #091930;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  color: var(--secondary-dark2);
}

.links {
  /* color: white; */
  display: flex;
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 300;
  gap: 2rem;
}

.links li {
  list-style: none;
}

.link {
  text-decoration: none;
  color: inherit;
  position: relative;
  cursor: pointer;
}

.links li a:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  /* background-color: $text-lightgray; */
  background-color: var(--secondary-dark);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.links li a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.logo img {
  height: 40px;
}

.btn-mobile-nav {
  display: none;

  border: none;
  background: none;
  cursor: pointer;

  z-index: 99;
}

.icon-mobile-nav {
  color: var(--secondary-dark2);
  font-size: 2.5rem;
}

.icon-mobile-nav[name='close-outline'] {
  display: none;
}

@media (max-width: 800px) {
  .navbar {
    padding: 0 1rem;
  }
}

/*Mobile Navigation*/
@media (max-width: 550px) {
  .main-nav {
    width: 100%;
    height: 100vh;
    /* z-index: 99; */
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--primary-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    transform: translateY(-100%);

    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  .light .main-nav {
    color: var(--primary-dark);
    background-color: var(--secondary-dark);
  }
  .links li a:after {
    height: 2px;
  }
  .light .links li a:after {
    background-color: var(--primary-dark);
  }

  .dark-mode-btn {
    font-size: 4rem;
  }

  .links {
    flex-direction: column;
    align-items: center;
  }
  .link {
    font-size: 4rem;
  }
  .btn-mobile-nav {
    display: block;
  }

  .nav-open.main-nav {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
  .nav-open .icon-mobile-nav[name='close-outline'] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name='menu-outline'] {
    display: none;
  }
}
