@import '../../general.css';

.skills {
  height: 100vh;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  margin: 0 2rem;
}

.skills .container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
}

.skills-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 2rem;
  width: 100%;
}

.skill-card {
  background-color: var(--primary-dark);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 11px 44px -16px var(--primary-dark);
}

.skill-card:hover {
  box-shadow: 0px 11px 44px -9px #8892b0;
  transform: scale(1.05);
}

.light .skill-card:hover {
  box-shadow: 0px 11px 44px -9px var(--primary-dark);
}

.skill-card h5 {
  color: var(--primary-med);
  font-size: 1.5rem;
  font-weight: 500;
}

.skill-card img {
  width: 90px;
}

@media (max-width: 800px) {
  .skills {
    margin: 0 1rem;
  }
  .skills-grid {
    gap: 1rem;
  }
}

@media (max-width: 525px) {
  .skill-card img {
    width: 50px;
  }

  .skill-card h5 {
    font-size: 1.3rem;
  }
}

@media (max-width: 450px) {
  .skills-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}
