@import '../../general.css';

.projects {
  height: max(100vh, 60rem);
  margin: 0 2rem;
}

.projects .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.projects h3 {
  margin-bottom: 1.5rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2rem;
  height: 700px;
  max-width: 100%;
  margin-bottom: 40rem;
}

.project-card {
  border-radius: 10px;
  background-size: cover;
  overflow: hidden;
  box-shadow: 0px 11px 44px -16px #000;
  transition: all 0.5s ease-in-out;
}

.project-card:hover {
  box-shadow: 0px 11px 44px -9px #8892b0;
  transform: scale(1.01);
}

.light .project-card:hover {
  box-shadow: 0px 11px 44px -9px var(--primary-dark);
}

.project-card img {
  object-fit: contain;
  max-width: 100%;
}

.project-card h4 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: white;
}

.project-card h5 {
  margin-bottom: 0.2rem;
}

.project-card .info {
  color: white;
  margin-top: 17rem;
  /* margin-top: 0; */
  height: 100%;
  padding: 1rem;
  padding-top: 0.5rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7368813736432073) 20%
  );
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
}

.project-card:hover .info {
  margin-top: 0;
}

.project-card .info p {
  margin-bottom: 1rem;
}

.light .project-card .info p {
  color: var(--text-dark1);
}

.tools {
  display: flex;
  gap: 0.7rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.tools li {
  list-style: none;
  background-color: var(--primary-med);
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
}

.project-card .buttons {
  display: flex;
  gap: 1rem;
  margin-top: auto;
  margin-bottom: 1.5rem;
}

.project-card .btn:link,
.project-card .btn:visited {
  text-decoration: none;
  padding: 5px 10px;
  background-color: var(--primary-dark);
  border: 2px solid white;
  color: white;
  font-weight: 300;
  font-size: 1.3rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.project-card .btn:hover,
.project-card .btn:active {
  border-color: var(--primary-med);
  background-color: var(--primary-med);
}

@media (max-width: 800px) {
  .projects {
    margin: 0 1rem;
  }

  .projects-grid {
    gap: 1rem;
    margin-bottom: 50rem;
  }

  .project-card .info {
    margin-top: 18rem;
  }
}

@media (max-width: 700px) {
  .projects {
    height: 100%;
  }

  .projects-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .project-card {
    height: 300px;
  }

  .project-card .info {
    margin-top: 0;
  }
}

@media (max-width: 500px) {
  .project-card {
    height: 350px;
  }
  .project-card .info {
    /* margin-top: 18.5rem; */
    margin-top: 0;
  }
}
