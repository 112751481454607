:root {
  --primary-dark: #0a192f;
  --primary-dark2: #233044;
  --primary-med: #0077b6;
  --secondary-dark: #8892b0;
  --secondary-dark2: #949db8;
  --text-dark1: #d1d5db;
  --light-bg: #949db8;
}

.container {
  height: 100%;
  color: var(--text-dark1);
  margin: 0 auto;
  max-width: 900px;
}

h3 {
  font-size: 2.5rem;
  position: relative;
  margin-bottom: 10px;
}
h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: var(--primary-med);
}

.light h3 {
  color: var(--primary-dark2);
}

.light p {
  color: var(--primary-dark2);
}
