@import '../../general.css';

.hero {
  height: calc(100vh - 70px);
  margin: 0 2rem;
}

.hero .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.hero h2 {
  color: var(--primary-med);
}

.hero h1 {
  color: var(--secondary-dark);
  font-size: 5rem;
}

.light .hero h1 {
  color: var(--primary-dark2);
}

.hero .btn {
  text-decoration: none;
  padding: 1rem;
  background-color: var(--primary-dark);
  margin-top: 3rem;
  /* border: 3px solid var(--primary-med); */
  border: 3px solid white;
  /* border-radius: 0.7rem; */
  /* color: var(--primary-med); */
  color: white;
  font-weight: 300;
  font-size: 1.3rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.hero .btn:hover {
  border-color: var(--primary-med);
  /* color: var(--secondary-dark); */
  background-color: var(--primary-med);
}

.arrow {
  display: flex;
  align-items: center;
  transition: all 0.3s;
  margin-left: 0.5rem;
  /* padding: 1px; */
}

.hero .btn:hover > .arrow {
  /* fill: red; */
  /* color: red; */
  transform: rotate(90deg);
  /* transform-origin: 4px 50%; */
  transform-origin: 15% 50%;
  transition: all 0.3s;
}

@media (max-width: 800px) {
  .hero {
    margin: 0 1rem;
  }
}

@media (max-width: 650px) {
  .hero h1 {
    font-size: 4rem;
  }
}

@media (max-width: 500px) {
  .hero h1 {
    font-size: 3rem;
  }
}

@media (max-width: 425px) {
  .hero .btn {
    font-size: 1.1rem;
  }

  .hero h1 {
    font-size: 2.8rem;
  }

  .hero h2 {
    font-size: 1.3rem;
  }

  .hero p {
    font-size: 0.9rem;
  }
}
