@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@100;200;300;400;500;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
}

.app {
  background-color: var(--primary-dark);
}

.light.app {
  background-color: var(--light-bg);
}
