@import '../../general.css';

.footer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #091930;
  color: var(--secondary-dark);
  /* border-top: 1px solid var(--text-dark1); */
}
